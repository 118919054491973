.login-header-new {
  background: #012526;
  padding: 18px 0px;
  color: #fff;
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  height: 13.3333333333vw;
  justify-content: center;
  align-items: center;
  /* display: flex; */
}

.login-header-new + .home-banner-sec {
  margin: 60px 0 10px 0;
}

.login-header-new + .full-market {
  margin-top: 53px;
  padding-bottom: 200px;
}

.login-header-new a {
  color: #fff;
  text-decoration: none;
  min-width: 62px;
  text-align: center;
  line-height: 25px;
  padding: 0px 2.1333333333vw;
  border-radius: 1.3333333333vw;
  font-size: 14px;
  display: inline-block;
  height: 28px;
}
.login-header-new figcaption {
  position: absolute;
  right: 13px;
}

.signup-btn {
  background: #61d81f;
  color: #222222 !important;
  margin-left: 12px;
}
.login-btn {
  background: #6f6d6d;
  color: #fff;
}
.main {
  padding: 13px;
  /* height: calc(100vh - 172px); */
  overflow-y: auto;
  margin-bottom: 20px;
  background: #111111;
}
.login-reg-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 35px;
}

.login-reg-wrapper figure {
  padding: 0px;
  margin: 0;
  margin-right: 0px;
  display: flex;
  align-items: center;
  width: 149px;
  margin-right: 5%;
  /* height: 13px; */
}

.login-reg-wrapper figure img {
  /* filter: brightness(0) invert(1); */
}

.footer-new {
  position: fixed;
  z-index: 104;
  bottom: 0;
  width: 100%;
  /* height: 13.3333333333vw; */
  /* border-top: 1.266667vw solid #333333; */
  background: linear-gradient(180deg, #4d4d4d 0%, #363636 100%);
  /* padding-top: 2px; */
}
.footer-payment-box span {
  color: #ffffff;
  font-size: 3.2vw;
}
.payment-sec .payment-box {
  width: auto;
  /* padding: 8px 6px; */
  text-align: center;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  min-width: 18.1818181818% !important;
  /* justify-content: space-between; */
}

.payment-sec {
  display: flex;
  /* flex-wrap: wrap; */
  margin: 0px 0px 13px;
  overflow: auto;
}

.payment-sec .payment-box span {
  font-size: 3.4666666667vw;
  color: #fff;
  font-weight: 700;
}

.heading-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00000021;
  padding-bottom: 11px;
  margin: -10px -9px 0px;
  padding: 9px 10px;
}

.heading-block h3 {
  font-size: 13px;
  font-weight: 500;
  color: #000000d9;
  font-weight: 900;
  margin-bottom: 0;
}

.heading-block a {
  text-decoration: none;
  font-size: 13px;
  color: #61d81fe8;
}

.slick-slide img {
  display: block;
  width: 100%;
}
.common-slider {
  padding: 13px;
  background: #333;
  margin: 0 -13px 0;
}
.banner-slider.border-0.p-0 {
  margin-bottom: 10px;
}
.common-slider h6 {
  font-size: 4vw;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: #fff;
}
.common-slider h2 {
  font-size: 12px;
}
.main-slider {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.common-slider span {
  font-size: 12px;
  display: block;
  margin-top: 4px;
}

.common-slider figcaption {
  padding: 7px 9px;
  /* position: absolute; */
  bottom: 0;
  background: #333;
  left: 0;
  right: 0;
  /* border-top: 1px solid #0000002b; */
}
.common-slider .slick-slide > div {
  margin: 10px 5px 0px;
}

.border-0 {
  border: none;
}

/*

.slick-slide {
  margin: 23px 9px 0px;
} */

.footer-new ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.footer-new ul li {
  width: 33.33%;
  text-align: center;
  list-style: none;
  padding: 3px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .footer-new ul li.active{background:#61d81f;} */

.footer-new ul li.active figure img {
  filter: none !important;
}
.footer-new ul li.active span {
  color: #61d81f !important;
}

.footer-new ul li a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
  font-family: "Inter";
  display: flex;
  width: 100%;
  padding: 10px 0px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: left;
}
.footer-new ul li .language-select-div {
  text-decoration: none;
  color: #000;
  font-weight: 700;
  font-family: "Inter";
  display: flex;
  /* width: 100%; */
  padding: 4px 0px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: left;
}
.footer-new ul li.language-select {
  background-color: #d7e3f0;
}
.footer-new ul li.register-button {
  background-color: #ffdf1a;
}
.footer-new ul li.login-button {
  background-color: #61d81f;
}
.footer-new ul li.login-button a {
  color: #fff;
}

.footer-new ul li a {
  text-decoration: none;
}

.slider-items {
  border: none;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
}

.banner-slider .slider-items {
  border: none;
}

.explore-slider .slider-items {
  overflow: visible;
}

.explore-slider .slick-slide img {
  display: block;
  max-width: 37px;
  margin: 0 auto;
}

.payment-sec .payment-box img {
  display: block;
  width: 10.6666666667vw;
  height: 10.6666666667vw;
  margin: 0 auto 1.8666666667vw;
  transition: all 0.3s;
  border-radius: 10.6666666667vw;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin-bottom: 2px;
}

.sportsbook-filter {
  filter: brightness(4) contrast(63.5);
}

.banner-slider .slick-slide > div {
  margin: 0px 0px 0px;
}
.banner-slider .slick-slide > div:first-child {
  margin-left: 0px;
}

.pay {
  background: #043b3d;
  /* margin: 0px -13px; */
  padding: 28px 22px;
}
.pay h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.payment-inner.gaming-lic {
  gap: 10px;
}
.pay ul {
  display: flex;
  padding: 0;
  /* flex-wrap: wrap; */
  background: #064b4d;
  padding: 2.1333333333vw !important;
  border-radius: 1.3333333333vw;
  flex-wrap: wrap;
}
.pay ul li img {
  width: 100%;
  max-height: 32px;
  /* filter: brightness(0) invert(1);
  filter: brightness(0.3) invert(1); */
}
.pay .gaming-lic ul li img {
  filter: none;
}
.pay ul li {
  padding-right: 17px;
  list-style-type: none;
}

.payment-inner {
  display: flex;
  margin-top: 33px;
  border-bottom: 0.2666666667vw solid #333333;
}

.payment-inner .payment-row {
  width: 50%;
}
.payment-row.social-row ul {
  flex-wrap: wrap;
}
.payment-row.social-row ul li {
  margin-bottom: 12px;
}

.language-sec {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 22px 0px;
  justify-content: center;
  background-color: #064b4d;
}

.language-sec h6 {
  color: #fff;
  padding-right: 10px;
  margin-bottom: 17px;
  width: calc(33.33% - 10px);
  /* text-align: center; */
  font-size: 12px;
  display: flex;
  position: relative;
  /* align-items: center; justify-content: center; */
}

.language-sec h6::before {
  position: absolute;
  left: -7px;
  content: "";
  background-color: #ffb61a;
  width: 1px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.language-sec h6 a {
  color: #ffb61a;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 700;
}

.paymentm {
  text-align: left;
}

.paymentm li {
  display: block;
  padding: 3px;
  padding-right: 9px !important;
}

.paymentm li img {
  height: 22px;
}

.login-banner .slider-items {
  border-radius: 0px;
}

.login-banner .slick-dots {
  bottom: 5px;
  padding: 0;
  background: #0003;
}

.login-banner .slick-dots li {
  padding: 0;
  cursor: pointer;
}

.login-banner .slick-dots li button:before {
  font-size: 13px;
  opacity: 1;
  color: #000;
}

.login-banner .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #61d81f;
}

.login-welcome {
  padding: 38px 26px;
  text-align: center;
}

.login-welcome {
  padding: 38px 26px;
  text-align: center;
}

.login-welcome h2 {
  font-size: 27px;
  line-height: 39px;
  font-family: "Alkatra";
  color: #fff;
}

.login-reg-btn {
  margin-top: 51px;
}

.login-reg-btn a {
  display: inline-block;
  padding: 13px 28px;
  text-decoration: none;
  border-radius: 25px;
  margin-bottom: 8px;
  font-size: 16px;
}

.login-reg-btn a.active {
  background: #61d81f;
  color: #fff;
}

.login-reg-btn a.active:hover {
  background: #fff;
  color: #61d81f;
  border: 1px solid #61d81f;
}
/*
.all-offer-link img {
  max-width: 31px;
  filter: brightness(0) invert(1);
} */

/* .all-offer-link {
  width: 20px;
  height: 20px;
  background: #61d81f;
  padding: 10px;
  display: block;
  border-radius: 100%;
} */

.menulist-sidebar {
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  z-index: 23;
  width: 287px;
  padding: 20px 0px;
  overflow-y: auto;
  transition: 0.5s;
}
.menulist-sidebar ul {
  padding: 0;
  margin-bottom: 0;
}

.menulist-sidebar ul li {
  list-style: none;
  border-bottom: 1px solid #00000017;
}

.menulist-sidebar ul li a {
  text-decoration: none;
  display: block;
  padding: 12px 15px;
  color: #000;
  display: flex;
  align-items: center;
}

.menulist-sidebar h2 {
  font-size: 19px;
  color: #61d81f;
}
.menulist-sidebar .menu-header {
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-menu-listing {
  /* margin: 0px -20px; */
  filter: brightness(0.9);
}
.menu-listing-bottom {
  padding: 0px 13px;
}
.menu-listing-bottom p {
  font-size: 15px;
  line-height: 22px;
  font-family: "Lato";
}
.menulist-sidebar .login-reg-btn {
  margin-top: 34px;
}
.menulist-sidebar ul li:last-child {
  border: none;
}

.menu-listing-bottom p a {
  color: #61d81f;
  text-decoration: none;
  font-weight: 800;
}
.menulist-sidebar .login-reg-btn a {
  border-radius: 0px;
}

.menu-header .button {
  text-decoration: none;
  /* color: #61d81f; */
  padding: 8px 19px;
  display: inline-block;
  border: 1px solid #61d81f;
  border-radius: 25px;
  /* margin-top: 21px; */
  font-size: 14px;
  background: #000;
  color: #fff;
  border-color: #fff;
}

.menulist-sidebar ul li a > div {
  margin-right: 14px;
}

.menulist-sidebar ul li a > div img {
  max-width: 22px;
}

.menulist-sidebar.active {
  right: 0;
}

.registration-form,
.login-form {
  position: relative;
  /* top: 0; */
  bottom: -100%;
  background: #043b3d;
  /* padding: 20px; */
  right: 0;
  left: 0;
  transition: 0.5s;
}
.registration-form .main-banner {
  background: none;
}
.reg-data h2 {
  font-size: 23px;
  font-weight: 100;
  line-height: 31px;
  color: #fff;
}
.reg-data h2 strong {
  font-size: 20px;
}
.registration-form label,
.login-form label {
  display: block;
  margin-bottom: 0;
  color: #ffffff;
  font-size: 3.7333333333vw;
  margin-right: 20px;
  width: 50%;
  line-height: 3;
}
.reg-data {
  margin-bottom: 16px;
  padding: 9px;
}

.registration-form .form-control,
.login-form .form-control {
  padding: 0 4.2666666667vw;
  margin-left: 0px;
  border: none;
  width: 100%;
  color: #fff;
  font-size: 15px;
  background: transparent;
  font-size: 3.4666666667vw;
  height: 12vw;
}

/* .registration-form .form-control:focus,
.login-form .form-control:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
} */

.registration-form .form-control:focus,
.login-form .form-control:focus {
  padding: 0 11.7333333333vw 0 4.2666666667vw !important;
  border: 0.2666666667vw solid #388a8c !important;
}

.registration-form form span,
.login-form form span {
  display: block;
  overflow: hidden;
  color: white !important;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
}

.reg-logo-login span.btn.btn-outline-primary svg
{
    font-size: 25px;
}
canvas#authenticateImage {
  background: #fff;
  border-radius: 6px;
  height: auto;
  height: 30px;
  position: absolute;
  right: 11px;
  margin-top: 5px;
  padding: 5px;
}

.reg-logo-login .form-control:focus{
color:#fff!important
}
.login-data-d .react-tel-input .form-control {
  background-color: transparent !important;
}

.login-data-d .react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: transparent !important;
  border: none !important;
  border-radius: 3px 0 0 3px;
}

.login-form .invalid-feedback {
  margin: 0 0 20px !important;
  color: #dc3545;
}
.registration-form .form-group,
.login-form .form-group {
  /* border-bottom: 1px solid #00000026; */
  padding-bottom: 8px;
}

.registration-form p,
.login-form p {
  color: #ffffff;
  font-size: 3.2vw;
  line-height: 1.2;
  text-align: center;
}

.registration-form p a,
.login-form p a {
  color: #ffffff;
  font-weight: 600;
}
.term-condtion {
  margin-top: 29px;
}
.back-header p {
  color: #fffc;
  font-size: 4.2666666667vw;
  text-align: center;
  margin: 0 !important;
}
.reg-data .reg-logo {
  width: 53.3333333333vw;
  height: 16vw;
  margin: 29px auto 26px;
}

/* .reg-data .reg-logo img {
  width: 100%;
  height: 100%;
} */
.login-data-d {
  background: #fff;
  /* display: block; */
  padding: 0px 10px;
  border-radius: 0.8vw 0.8vw 0vw 0vw;
  height: 60px;
  align-items: center;
  margin-bottom: 0px !important;
  /* border: #ccc thin solid; */
}

.reg-data .login-data-d {
  background: #303030;
  border-bottom: rgba(255, 255, 255, 0.3) thin solid;
}

.reg-data .login-data-d input[type="text"]:focus {
  outline: none !important;
  background-color: transparent !important;
}

.reg-data .login-data-d .form-control:focus {
  color: #fff;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #999;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #999;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #303030 inset !important;
}
input.login:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.back-header {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  height: 13.3333333333vw;
  border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3);
  background: #303030;
  align-items: center;
  position: relative;
}
.forgot-back-header {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  height: 13.3333333333vw;
  border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3);
  background: #303030;
  align-items: center;
  position: relative;
}
.forgot-back-header p {
  color: #ffffff;
  font-size: 28px;
  text-align: center;
  margin: 0 !important;
}
.forgot-back-header span {
  filter: invert(1);
}
.left-arrow {
  position: absolute;
  left: 16px;
}

.back-header span,
.forgot-back-header span {
  display: block;
  transform: rotate(180deg);
  filter: invert(1);
}

.registration-form .login-form {
  position: fixed;
  top: 100%;
  background: rgb(17, 17, 17);
  padding: 20px;
  right: 0;
  left: 0;
  transition: 0.5s;
  height: 100%;
}
.registration-form.active,
.login-form.active {
  top: 0px;
}
.login-info-box {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0 2.6666666667vw;
}
.forgetpassword-buttn {
  font-size: 3.4666666667vw;
}
.forgetpassword-buttn a {
  padding: 3px;
  border: 1px solid #fff200;
  border-radius: 0.8vw;
  color: #ffffff;
  text-decoration: none;
}

.overlay {
  background: #0000008c;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  top: z;
  top: 0;
  z-index: 2;
  position: absolute;
  z-index: 20;
}

.registration-form form {
  margin-top: 29px;
}

input[type="text"]:focus {
  outline: none !important;
}

/* .slider-items.slider-items-offer figure {
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.footer-new ul li.language-select img {
  max-width: 25px;
  /* margin-right: 14px; */
}

.footer-bottom h6 {
  margin-bottom: 1.3333333333vw;
  color: #0088da;
  font-size: 3.4666666667vw;
  font-weight: 700;
}
.footer-bottom p {
  color: #ffffff80;
  font-size: 3.2vw;
  margin-bottom: 0px;
}

.footer-bottom {
  padding: 17px 0px 0px;
}

.next-btn button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  padding: 13px 20px;
  background: #61d81f;
  color: #fff;
}

.next-btn {
  margin: 0px -20px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.registration-form form .next-btn span {
  font-weight: 600;
  font-family: "Inter";
  font-size: 16px;
}

.next-btn button:disabled {
  background: #00000057;
}

.next-btn button:disabled span {
  color: #fff;
}
.footer-new ul li.language-select img {
  max-width: 25px;
  margin-right: 14px;
}
.next-btn img {
  max-width: 24px;
  filter: brightness(0) invert(1);
}

.theme-btn-new {
  background: #61d81f;
  color: #fff;
  border: none;
  padding: 13px 15px;
  font-size: 16px;
  transition: 0.5s;
  border: 1px solid #61d81f;
  font-size: 14px;
  font-weight: 700;
}

.theme-btn-new:hover {
  background: transparent;
  color: #61d81f;
}

.otp-verfication {
  padding: 0px 23px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

input.form-control {
  padding: 10px 7px;
}

.otp-verfication .form-group {
  display: flex;
}

.otp-verfication .form-group input[type="text"] {
  width: calc(25% - 10px);
  margin: 32px 5px;
  border-radius: 7px;
  padding: 17px 12px;
  border: 1px solid #ced4da;
  text-align: center;
  font-size: 19px;
}

.otp-verfication h4 {
  font-size: 19px;
  margin-bottom: 11px;
}

.otp-sent {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  /* font-family: 'Inter'; */
}

.otp-sent strong {
  color: #fff;
}

.otp-verfication .resent-otp {
  margin-bottom: 28px;
  font-size: 14px;
}

.resent-otp span {
  text-decoration: none;
  color: #61d81f;
  font-weight: 600;
  /* font-size: 15px; */
}

.otp-verfication figure img {
  width: 100%;
  max-width: 182px;
}
.otp-verfication figure {
  margin-bottom: 71px;
}

.pay ul li a {
  display: block;
}
.react-tel-input .form-control {
  border: none !important;
  font-size: 15px !important;
  padding-left: 48px !important;
}
.text-danger {
  color: red;
  font-size: 14px;
}

.form-control:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.btn-remove {
  border: none;
  padding: 10px 0;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  border-radius: 20px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.main-slider-div {
  display: block;
}
.main-slider-inner-div {
  padding: 0 10px;
}

.main-slider-inner-div h5,
.common-slider h5 {
  margin-bottom: 0px;
  color: #fff;
  font-size: 4vw;
}
.main-slider-inner-div h5:before,
.common-slider h5:before {
  content: "";
  display: inline-block;
  width: 1.0666666667vw;
  height: 4vw;
  margin-right: 1.3333333333vw;
  background-color: #61d81f;
}

.main-slider-inner-div ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 0 !important;
}
.main-slider-inner-div ul li {
  position: relative;
  width: 25%;
  border: 0.1333333333vw solid #111111;
  background-color: #333;
  padding: 0;
}
.main-slider-inner-div ul li div {
  padding: 2.6666666667vw 0;
}
.main-slider-inner-div ul li div span {
  height: 38px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}
.main-slider-inner-div ul li div img {
  display: block;
  width: 50%;
  margin: 0 auto 1.3333333333vw;
}
.main-slider-inner-div ul li div p {
  color: #fff;
  margin: 0px;
  font-size: 3.4666666667vw;
  text-align: center;
}
.casino-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.3333333333vw;
}

.casino-card {
  position: relative;
  width: 46vw;
  height: auto;
  margin: 0 1.3333333333vw 2.6666666667vw;
  overflow: hidden;
  border-radius: 0.8vw;
}
.footer-payment-box figure img {
  width: 25px;
  height: 25px;
  filter: invert(1);
}
.payment-box.footer-payment-box figure {
  margin: 0;
}
.footer-payment-box span {
  font-weight: 500;
}

.login-header-new + .main-banner {
  margin-top: 54px;
}

.main-banner {
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 3px;
  background: #111111;
}
.slider-items-banner {
  padding: 0 5px;
  height: 100%;
}
.slider-items-banner figure img {
  border-radius: 7px;
}
.main-banner .slick-dots li {
}

/* .slick-list {
  overflow: ${props => (props.overflow ? "visible" : "hidden")};
} */
/* Slider */
.main-banner .slick-slider {
  margin-bottom: 8px;

  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.main-banner .slick-list {
  position: relative;

  display: block;
  /* overflow: hidden; */

  margin: 0;
  padding: 0;
}
.main-banner .slick-list:focus {
  outline: none;
}
.main-banner .slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.main-banner .slick-slider .slick-track,
.main-banner .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.main-banner .slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.main-banner .slick-track:before,
.main-banner .slick-track:after {
  display: table;

  content: "";
}
.main-banner .slick-track:after {
  clear: both;
}
.main-banner .slick-loading .slick-track {
  visibility: hidden;
}

.main-banner .slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.main-banner .slick-slide img {
  display: block;
  width: auto !important;
  height: auto !important;
}
.main-banner .slick-slide.slick-loading img {
  display: none;
}
.main-banner .slick-slide.dragging img {
  pointer-events: none;
}
.main-banner .slick-initialized .slick-slide {
  display: block;
}
.main-banner .slick-loading .slick-slide {
  visibility: hidden;
}
.main-banner .slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.main-banner .slick-arrow.slick-hidden {
  display: none;
}
/* Arrows */
.main-banner .slick-prev,
.main-banner .slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.main-banner .slick-prev:hover,
.main-banner .slick-prev:focus,
.main-banner .slick-next:hover,
.main-banner .slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.main-banner .slick-prev:hover:before,
.main-banner .slick-prev:focus:before,
.main-banner .slick-next:hover:before,
.main-banner .slick-next:focus:before {
  opacity: 1;
}
.main-banner .slick-prev.slick-disabled:before,
.main-banner .slick-next.slick-disabled:before {
  opacity: 0.25;
}

.main-banner .slick-prev:before,
.main-banner .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-banner .slick-prev {
  left: 5px;
  z-index: 9;
}
.main-banner .slick-next {
  right: 5px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.main-banner .slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.main-banner .slick-dots {
  position: absolute;
  bottom: -20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
  list-style: none;
  text-align: center;
}
.main-banner .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}
.main-banner .slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 10px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.main-banner .slick-dots li button:hover,
.main-banner .slick-dots li button:focus {
  outline: none;
}
.main-banner .slick-dots li button:hover:before,
.main-banner .slick-dots li button:focus:before {
  opacity: 1;
}
.main-banner .slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-banner .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

/* Custom Slick Dots */

@keyframes loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.main-banner .ft-slick__dots--custom {
  height: 2px;
  width: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  position: relative;
  top: 0;
}

.main-banner .slick-dots li {
  width: 30px;
  vertical-align: top;
  /* margin: 0 2px; */
  transition: width 0.3s ease-in-out;
}

.main-banner .slick-dots .slick-active {
  width: 30px;
  transition: width 0.3s ease-in-out;
}

.main-banner .slick-dots .slick-active .ft-slick__dots--custom {
  width: 30px;
  overflow: hidden;
}
.main-banner .slick-dots .slick-active .ft-slick__dots--custom .loading {
  height: 4px;
  animation: loading 3s ease-in;
  background-image: linear-gradient(270deg, #ffb61a, #ffb61a);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}

.login-header-new .bg-transparent svg {
  font-size: calc(2rem + 0.3vw) !important;
  fill: #ffb80c;
}

/* .paise {
  margin-left: 30px;
} */
.login-banner .slider-items {
  border: none;
}
.login-banner .slick-dots {
  bottom: 15px;
}
.login-reg-wrapper figure img {
}

/*=====*/
.bg1 {
  background: url(assets/images/background1.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 50px 0;
  color: #fff;
  padding-top: 0px;
}
.index_title2 {
  padding: 10px;
  border: 1px solid #00000030;
  border-radius: 6px;
  margin-bottom: 15px;
  background: rgb(35, 38, 41, 0.5);
  padding: 18px 0;
  align-items: center;
}
.afi-text {
  color: #fff;
  font-size: 12px;
  margin-bottom: 0;
}
.afi-text1 {
  color: #f3cca4;
  font-weight: bold;
  padding-bottom: 0;
}
.bg2 {
  position: relative;
  padding: 50px 0;
  color: #fff;
  background-image: url(assets/images/background2.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  border-top: #434141 thin solid;
}
.bg2 h2 {
  letter-spacing: 6px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 26px;
}

.bg2 .img-fluid {
  margin-top: 40px;
}

.bg2 p {
  font-size: 12px;
  margin-top: 10px;
}

.index_exhibitor {
  background: url(assets/images/event_bg.webp);
  position: relative;
  padding: 50px 0;
  color: #fff;
  background-position: center;
  background-size: cover;
  display: flex;
}
.btn_index {
  background: rgb(128, 194, 169);
  background: linear-gradient(
    180deg,
    rgba(128, 194, 169, 1) 0%,
    rgba(33, 147, 103, 1) 29%,
    rgba(20, 112, 76, 1) 60%,
    rgba(15, 100, 67, 1) 91%
  );
  border-radius: 10px;
  color: #fff !important;
  padding: 10px 20px;
  font-size: 14px;
  border: 2px solid rgba(20, 112, 76, 1);
}

.referal_liks_top {
  display: block;
  text-align: center;
  padding: 10px 10px 0;
}

.referal_liks_top:last-child p {
  margin-bottom: 0px;
}

.affilates-page .slick-slider {
  padding: 0 50px !important;
}

.affilates-page .slick-current + div img {
  width: 120%;
  border: 4px solid white;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 7px rgba(255, 255, 255, 0.77);
}

.me-auto.nav {
  display: none;
}

@media only screen and (max-width: 1920px) and (min-width: 768px) {
  .me-auto.nav {
    display: block;
  }
  .main {
    padding: 13px 0;
    overflow-y: auto;
    margin-bottom: 35px;
    max-width: 75rem;
    margin: auto;
  }

  .main-slider-inner-div ul li div span {
    height: 120px;
  }
  .main-banner {
    height: auto;
  }
  .main-banner .slick-dots {
    bottom: -38px;
  }
  .payment-sec .payment-box img {
    max-height: 42px;
    max-width: 42px;
  }
  .pay {
    padding: 5rem;
  }
  .footer-bottom h6 {
    font-size: 14px;
  }
  .footer-bottom p {
    font-size: 14px;
  }
  .heading-block h3 {
    font-size: 1.25rem;
  }
  .heading-block a {
    font-size: 1.25rem;
  }
  .common-slider h6 {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  .main-slider-inner-div ul li div p {
    font-size: 2.5vw;
  }
  .main-slider-inner-div h5 {
    align-items: center;
    display: flex;
  }

  .marquee-notification.d-flex {
    max-width: 75rem;
    margin: 0 auto -13px;
  }
  .slider-items-banner {
    padding: 0;
  }
  .slider-items-banner figure img {
    border-radius: 0;
    width: 100% !important;
    height: auto !important;
  }


  .slider-items {
    border: none;
  }

  .login-banner {
    padding: 0 12rem;
    background: #bf0e80;
  }
  .login-banner .slick-dots {
    background: transparent;
  }
  .main-slider-inner-div ul li {
    width: 20%;
  }
}

.afilate-form {
  padding: 10px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  /* height: 100%; */
}
/* .afilate-form div .form-group{
  width: 100%;
} */

.afilate-form .react-tel-input .form-control {
  border: 1px solid grey !important;
  font-size: 15px !important;
  /* font-weight: 600 !important; */
}

.afilate-slider .afilate {
  margin-top: 30px;
  /* display: flex;
  align-items: center;
  flex-direction: column; */
  padding: 10px;
}
.afilate-slider .afilate h3 {
  padding-left: 12px;
}

.main-new {
  padding: 0 !important;
}
.afilate-slider .slick-prev {
  top: 43% !important;
  width: 30px !important;
  height: 40% !important;
  left: 15px !important;
  z-index: 999999999999999;
}
.afilate-slider .slick-next {
  top: 43% !important;
  width: 30px !important;
  height: 40% !important;
  right: 15px !important;
  z-index: 999999999999999;
}
.afilate-slider .affilate-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}
.afilate-slider .affilate-slider img {
  margin-bottom: 0;
  width: 100%;
  margin: 5px auto;
}
/* .slick-center{
  background-color: red !important;
} */
.afilate-slider .slick-center img {
  width: 120% !important;
  border: 4px solid white;
  border-radius: 10px;
  margin-top: -5px;
  -webkit-box-shadow: 0px 0px 12px 7px rgba(255, 255, 255, 0.77);
  -moz-box-shadow: 0px 0px 12px 7px rgba(255, 255, 255, 0.77);
  box-shadow: 0px 0px 12px 7px rgba(255, 255, 255, 0.77);
}
.afilate-slider .slick-center p {
  font-size: 17px !important;
}
.afilate-slider .affilate-slider p {
  font-size: 14px;
}

.afilate-slider .slick-slide > div > div {
  padding: 10px;
}
.header-right-btn-group a {
  color: #61d81f;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  text-align: center;
  font-size: 3.2vw;
  font-weight: 700;
  word-wrap: break-word;
  word-break: break-all;
}
.header-right-btn-group a img {
  height: 21px;
}
.header-right-btn-group {
  display: flex;
  min-width: 90px;
}
.login-reg-wrapper button {
  min-width: 40px;
  text-align: left;
  position: absolute;
  left: 0;
}

.footer-license {
  padding: 1.8666666667vw 0;
  border-bottom: 0.2666666667vw solid #333333;
}
.footer-license .license-title {
  color: #ffffff80;
  font-size: 3.2vw;
  font-weight: 700;
  line-height: 1.5;
}
.footer-license p {
  color: #ffffff80;
  font-size: 3.2vw;
  line-height: 1.2;
}

.promotion-box {
  position: relative;
  width: 94.6666666667vw;
  height: 68vw;
  overflow: hidden;
  border-radius: 0.8vw;
  background: #ffffff;
  color: #111;
  margin: 0 auto 2.6666666667vw;
}
.promotion-box .pic {
  position: relative;
  width: 100%;
  height: 31.4666666667vw;
}
.promotion-box .pic img {
  display: block;
  width: 100%;
  height: 100%;
}
.promotion-box .pic .item-bg {
  content: "";
  display: block;
  position: absolute;
  bottom: -1.3333333333vw;
  left: 0;
  width: 100%;
  mask-repeat: no-repeat;
  mask-position: center top;
  mask-size: 100%;
}
.promotion-box .promotion-box-inner {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 2.6666666667vw;
  padding: 2.6666666667vw;
}
.promotion-box .promotion-box-inner .text-main {
  display: block;
  width: 100%;
  max-height: 12.8vw;
  overflow: hidden;
  transition: all 0.6s;
}
.content-style h3 {
  margin-bottom: 1.3333333333vw;
  overflow: hidden;
  color: #333;
  font-size: 4.2666666667vw;
  font-weight: 700;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content-style p {
  min-height: 6.6666666667vw;
  margin: 2.6666666667vw 0;
  font-size: 3.4666666667vw;
  line-height: 1.5;
}
.content-style .times {
  position: relative;
  margin-bottom: 2.6666666667vw;
  font-size: 3.4666666667vw;
}
.content-style .button-box {
  display: flex;
}
.promotion-box .button.btn-primary {
  background: #0088da;
  position: relative;
  width: 100%;
  text-align: center;
  color: #fff;
  height: 32px;
  line-height: 32px;
  font-weight: 600;
}
.content-style .button-box .button:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 100%;
}
.promotion-box .new_tag {
  background-color: #008aff;
  color: #fff;
  position: absolute;
  top: 11px;
  left: 10px;
  font-size: 14px;
  padding: 0 5px;
}
.member-menu {
  position: fixed;
  padding-bottom: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.4s;
  background: #111111;
  -webkit-overflow-scrolling: touch;
}
.member-menu .close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 13.3333333333vw;
  height: 13.3333333333vw;
  border-radius: 0 0 0 100%;
  background: #111111;
}
.member-header {
  position: relative;
  width: 100%;
  height: 40vw;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  background-image: url("assets/images/member-header-bg.png");
}
.member-header .member-header-content {
  bottom: 18.6666666667vw;
  left: 2.6666666667vw;
  text-align: center;
}
.member-header-content {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  bottom: 32vw;
  left: 2.6666666667vw;
  z-index: 1;
}
.member-header-content .pic {
  display: inline-block;
  width: 17.3333333333vw;
  height: 17.3333333333vw;
  margin-right: 2.6666666667vw;
  border-radius: 17.3333333333vw;
  background-color: #111;
  background-repeat: no-repeat;
  color: #61d81f;
  font-size: 9.6vw;
  line-height: 17.3333333333vw;
  text-align: center;
  vertical-align: middle;
  box-shadow: 2px 4px 7px #0000003d;
  background-position: center;
  background-size: contain;
}
.member-header-content .infor {
  display: inline-block;
  width: 74.6666666667vw;
  vertical-align: middle;
}
.member-header-content .account {
  color: #fff;
  font-size: 4.2666666667vw;
}
.member-header-content .account span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  width: 100%;
  max-height: 17.0666666667vw;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 4.2666666667vw;
}
.member-header-content .vip-points {
  display: inline-flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 100%;
  width: auto;
  min-height: 6.4vw;
  padding: 0.5333333333vw 2.6666666667vw;
  margin-bottom: 0.5333333333vw;
  border-radius: 6.4vw;
  background: #262626;
  color: #fff;
  font-size: 3.2vw;
  white-space: nowrap;
  line-height: 1.5;
  overflow: scroll;
}
.member-header-content .vip-points.active span {
  flex: 0 1 25px;
  max-width: none;
  overflow: visible;
}
.member-header-content .vip-points .myvip-text .item-icon {
  display: inline-block;
  flex: 0 0 2.9333333333vw;
  width: 2.9333333333vw;
  height: 2.6666666667vw;
  margin: 0 0 0 1.3333333333vw;
  background: #ffffff;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-size: cover;
  mask-size: cover;
  vertical-align: middle;
}
.member-menu-content.bonuswallet {
  margin: 0;
  top: 0;
  padding: 0;
}
.member-menu-content {
  position: fixed;
  background: #000;
  left: 0%;
  transition: all 2s ease;
  top: 80px;
  bottom: auto;
  z-index: 999;
  width: 100% !important;
  padding-bottom: 50px;
  overflow: auto;
  padding: 10px;
  height: 100vh;
  padding-bottom: 50px !important;
}
.member-menu-box.balance-box {
  display: flex;
  position: relative;
  flex-direction: row;
  background-color: #064b4d;
}
.member-menu-box {
  width: 100%;
  height: auto;
  margin: 0 0 2.6666666667vw;
  border-radius: 0;
  background: #043b3d;
}
.member-list {
  margin: 2.1333333333vw;
  width: calc(100% - 5vw);
}
.member-menu-box.balance-box .balance.balance-row {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.member-menu-box.balance-box .balance .text {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  line-height: 4.8vw;
  white-space: nowrap;
}
.member-menu-box .title {
  width: 100%;
  padding: 1px 2.6666666667vw;
  border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3);
  line-height: 9.3333333333vw;
}
.member-menu-box .title h2 {
  color: #fff;
  font-size: 4vw;
  margin: 8px 0;
}
.member-menu-box .title h2:before {
  content: "";
  display: inline-block;
  width: 1.0666666667vw;
  height: 4.2666666667vw;
  margin-right: 1.3333333333vw;
  background: #61d81f;
  vertical-align: middle;
}
.member-menu-box .title h2 span {
  display: inline-block;
  vertical-align: middle;
}
.member-menu-box ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  margin: 0;
}
.member-menu-box ul.align-center li {
  flex: auto;
  width: 33.3333333333%;
}
.member-menu-box ul li a {
  display: block;
  text-decoration: none;
  height: auto;
}
.member-menu-box ul li a .item-icon {
  display: block;
  width: 9.3333333333vw;
  height: 9.3333333333vw;
  margin: 0 auto 1.3333333333vw;
  border-radius: 9.3333333333vw;
  background: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.member-menu-box ul li a p {
  color: #fff;
  margin: 0;
  font-size: 3.4666666667vw;
  text-align: center;
}
.member-menu-logout {
  position: relative;
  height: 12.8vw;
  border-radius: 1.3333333333vw;
  color: #fff;
  font-size: 5.3333333333vw;
  font-weight: 500;
  line-height: 12.8vw;
  text-align: center;
  /* box-shadow: 0 .5333333333vw .5333333333vw; */
  text-shadow: none;
  background: #388a8c;
  margin: 2.1333333333vw;
  width: calc(100% - 5vw);
  display: flex;
  align-items: center;
  justify-content: center;
}
.member-menu-logout a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.666667vw;
  color: #fff;
  text-decoration: none;
  margin-top: -6px;
}

.member-menu-logout a img {
  /* filter: invert(1); */
}

.member-menu-logout a p {
  display: inline-block;
  color: #fff;
  font-size: 3.4666666667vw;
  text-align: center;
  margin: 0;
}
.member-menu-box.balance-box .balance {
  display: inline-flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  height: 18.4vw;
  padding: 2.6666666667vw;
  font-size: 3.2vw;
}
.member-menu-box.balance-box .balance .amount i {
  display: inline-block;
  position: relative;
  transition: all 0.1s ease-in;
  color: #fff;
}
.member-menu .close:after {
  transform: rotate(-45deg);
}
.member-menu .close:before {
  transform: rotate(45deg);
}
.member-menu .close:before,
.member-menu .close:after {
  content: "";
  display: block;
  position: absolute;
  top: 40%;
  left: 60%;
  width: 5.3333333333vw;
  height: 0.5333333333vw;
  margin: -0.2666666667vw 0 0 -2.6666666667vw;
  border-radius: 0.5333333333vw;
  background: #ffffff;
}
.p-title svg {
  color: #61d81f;
}

.common-slider .slick-slider {
  overflow-x: auto;
  overflow-y: hidden;
}
.common-slider .slick-dots li button {
  background-color: rgba(255, 255, 255, 0.5);
}
.common-slider .slick-dots li button:before {
  background-color: rgba(255, 255, 255, 0.5);
}
.common-slider .slick-dots {
  bottom: -15px;
}
.common-slider .slick-dots li {
  height: 17px;
}

.common-slider-box .sports-tab-item {
  margin: 0;
  padding: 0 5px;
}

.common-slider-box .slider-items,
.common-slider-box .sports-tab-item {
  border-radius: 0.8vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin: 0;
  padding: 0 5px;
  color: #fff;
}
.sports-tab-panel .sports-tab-item .active {
  background-color: #04aa6d;
  color: #fff;
}
.sports-tab-panel h3 {
  border-radius: 4px;
  font-size: 12.48px !important;
  font-weight: 700;
  padding: 5px 10px;
  position: relative;
  text-align: center;
}
.index_country p {
  padding-top: 10px;
  color: #f7f7f7;
  text-transform: uppercase;
  font-family: "Roboto Condensed";
  letter-spacing: 1px;
  font-weight: 200;
  margin-bottom: 5px;
}
.index_country h3 {
  color: #ffffff;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: "Roboto Condensed";
  letter-spacing: 1px;
  font-weight: 800;
  padding-bottom: 10px;
}
.afilate-form label {
  color: #ffffff;
}
.index_country .index_title2 {
  background: rgb(35, 38, 41, 0.5);
  padding: 30px 0;
  align-items: center;
}
.affiliate-data h4 {
  font-size: 12px !important;
  color: #fff;
  margin-bottom: 0;
  text-align: left;
}

.affiliate-data h3 {
  color: #f3cca4;
  font-weight: bold;
  padding-bottom: 0;
  font-size: 15px;
}

section.index_brand {
  min-height: 0;
  background-image: url("https://bjaffiliates.com/img/country_option/background2mb.jpg");
  position: relative;
  padding: 50px 0;
  background-position: center;
  background-size: cover;
  display: flex;
}

.index_brand h2 {
  color: #fff;
  font-size: 16px;
  letter-spacing: 6px;
  text-transform: uppercase;
}
.index_brand p {
  color: #fff;
  margin-top: 0px;
  font-size: 12px;
  margin-bottom: 10px;
}

section.index_country {
  background-image: url("assets/images/background1mb.jpg");
  background-position: center;
  background-size: cover;
}
.afilate-form {
  background: rgb(35, 38, 41, 0.5);
  padding: 30px 15px;
}

.index_country .slick-initialized {
  padding: 0 34px;
}
.index_country .slick-initialized .slider-items {
  padding: 0 15px;
}
.index_country
  .slick-slide.slick-active.slick-current
  + .slick-slide.slick-active
  figure
  img {
  border: 4px solid white;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 7px rgba(255, 255, 255, 0.77);
  /* margin: 15px; */
  /* vertical-align: top; */
  /* scale: 120%; */
  width: 100%;
}

.index_country .slick-track {
  display: flex !important;
  align-items: center;
}
.index_country
  .slick-slide.slick-active.slick-current
  + .slick-slide.slick-active
  p {
  font-size: 15px;
  font-weight: bold;
}
.index_country p {
  font-size: 10px;
  text-align: center;
}
.index_country .slick-next {
  right: 10px !important;
  background-image: url("assets/images/Layer 10 copy 2.png") !important;
  height: 47px;
  width: 26px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.index_country .slick-prev {
  left: 15px !important;
  z-index: 999 !important;
  background-image: url("assets/images/Layer 10 copy.png") !important;
  height: 47px;
  width: 26px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.index_country .slick-next:before {
  display: none;
}
.index_country .slick-prev:before {
  display: none;
}
.index_country .slick-slide img {
  width: 90%;
}
.index_country .slick-slide figure {
  margin: 10px 0 1rem;
  min-height: 68px;
}

.common-slider-box {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.common-slider-box .slider-items {
  display: inline-block;
  position: relative;
  width: 72vw;
  height: 41.3333333333vw;
  margin: 1.3333333333vw 2.6666666667vw 1.3333333333vw 0;
  border-radius: 0.8vw;
  background: #333333;
  box-shadow: 0 0 0.8vw #000c;
  vertical-align: top;
}
.gamefixed {
  position: fixed;
  top: 54px;
  z-index: 9;
  width: 100%;
  background: #111;
  transition: all 0.3s;
}
.gamefixed .payment-box figure img {
  display: none;
}
.gamefixed .home-tabing-slider .payment-sec .payment-box {
  padding: 0;
}
.gamefixed .payment-box figure {
  margin: 0 !important;
}
.select-group li {
  display: inline-block;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-group.checkbox-style [type="radio"]:checked + label {
  transition: all 0.3s;
  border: 0.2666666667vw solid #ffdf1a;
  background: #333333;
  color: #ffdf1a;
}
.select-group.checkbox-style [type="radio"] + label {
  padding: 1.3333333333vw 2.6666666667vw;
}
.select-group [type="radio"] + label {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  border: 0.2666666667vw solid #5e5e5b;
  border-radius: 0.8vw;
  background: #333333;
  color: #fff;
  font-size: 3.2vw;
  line-height: 9.3333333333vw;
  text-align: center;
  padding: 2vw 2.6666666667vw;
}
.select-group .bank {
  margin-bottom: 1.3333333333vw;
}
.select-group .bank img {
  display: block;
  width: auto;
  height: 6.6666666667vw;
}
.select-group.checkbox-style [type="checkbox"] + label span,
.select-group.checkbox-style [type="radio"] + label span {
  line-height: 1.5;
}
.select-group [type="checkbox"],
.select-group [type="radio"] {
  width: 105px;
  height: 34px;
  position: absolute;
  z-index: 9;
  opacity: 0;
}
.select-group ul {
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  margin: 0;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, calc((100% - 20px) / 3));
}
.select-group label span {
  line-height: 1.5;
}
.select-group.checkbox-style [type="radio"]:checked + label .item-icon {
  display: block;
  position: absolute;
  z-index: 2;
  right: -0.2666666667vw;
  bottom: 0.733333vw;
  width: 5.3333333333vw;
  height: 4.2666666667vw;
  opacity: 1;
}
.select-group.checkbox-style [type="radio"] + label .item-icon {
  display: none;
}
.input-group.money {
  padding: 0 0 2.6666666667vw;
}
.input-group {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 2.6666666667vw;
  font-size: 3.2vw;
  background: #333333;
  line-height: 1.5;
}
.input-group.money label {
  position: absolute;
  z-index: 2;
  top: 5.3333333333vw;
  left: 1.3333333333vw;
  transform: translateY(-50%);
  color: #61d81f;
}
.input-group.money .input-wrap {
  z-index: 1;
}
.input-group.money .input-wrap input {
  color: #61d81f;
  text-align: right;
}
.input-group input:disabled {
  border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3);
  border-radius: 0;
  background: none;
}
.delete-btn {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 10.6666666667vw;
  height: 10.6666666667vw;
  transition: all 0.3s;
  background: #61d81f;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 30%;
  mask-size: 30%;
  opacity: 0;
}
.tips-info {
  position: relative;
  flex: 1;
  margin: 0;
  padding: 2.6666666667vw;
  border: 0.2666666667vw solid #0a3e2d;
  border-radius: 1.3333333333vw;
  background: #03120d;
  text-align: left;
  white-space: normal;
}
.player-deposit-wrap .tips-info.note h5,
.withdraw-wallet .tips-info.note h5 {
  width: 100%;
  margin-bottom: 0;
  align-items: flex-start;
}
.player-deposit-wrap .tips-info.note span,
.withdraw-wallet .tips-info.note span {
  color: #fff;
  font-size: 3.2vw;
  line-height: 1.2;
  white-space: pre-wrap;
}

.usrTrans-form .member-menu-box .title {
  width: 100%;
  padding: 0;
  border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3);
  line-height: 9.3333333333vw;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.usrTrans-form .member-menu-box .title i {
  display: flex;
  color: #999;
  font-size: 2.6666666667vw;
  white-space: nowrap;
}
.usrTrans-form .member-menu-box {
  border-radius: 0.8vw;
  width: 100%;
  height: auto;
  margin: 0 0 2.6666666667vw 0;
  padding: 2vw 4vw 4vw 4vw;
  background: #333333;
}
.usrTrans-form .select-group ul {
  padding: 0px !important;
}

.select-group ul.col4 {
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  margin: 0;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, calc((100% - 30px) / 4));
}
.input-group.money .input-wrap input {
  color: #61d81f;
  text-align: right;
  width: 100%;
  background: transparent;
  border: none;
  padding: 10px 10px 0 0;
}

.tips-info.note span {
  color: #fff;
  font-size: 3.2vw;
  line-height: 1.2;
  white-space: pre-wrap;
}

.refer-header {
  align-items: center;
  background-color: #61d81f;
  color: #fff;
  display: flex;
  height: 120px;
  justify-content: center;
}
.refer-main {
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  margin-top: -10px;
  padding: 27px 17px;
  padding-bottom: 50px !important;
  position: relative;
}
.refer-main-top {
  margin-bottom: 15px;
}
.refer-button button:first-child {
  background-color: green;
  color: #fff;
  border: navajowhite;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
}
.refer-button button:last-child {
  background-color: grey;
  color: #fff;
  border: navajowhite;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
}
.index_country .input-group {
  padding-bottom: 0.25rem !important;
  background: none;
}

.index_country .input-group .form-control {
  padding: 0.25rem !important;
  width: 100%;
  border-radius: 0.375rem !important;
}

.index_country .input-group span {
  cursor: pointer;
  position: absolute;
  right: 0;
  border: none;
  top: 3px;
}

.index_country .slick-prev:hover,
.index_country .slick-prev:focus,
.index_country .slick-next:hover,
.index_country .slick-next:focus {
  background-size: 100% !important;
  background-repeat: no-repeat !important;
}
.top-class {
  overflow: auto;
  padding-top: 52px;
}
.refer-share-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.menu-second ul {
  position: absolute;
  top: 10.6666666667vw;
  left: -29.3333333333vw;
  width: 29.3333333333vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.5s;
  background: #ffffff4d;
  z-index: 999;
  box-shadow: 0 0 #0000;
  padding: 0px 0 50px;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  border-radius: 1.3333333333vw;
}
.menu-second ul.active {
  left: 277px;
  box-shadow: 0.8vw 0 0.8vw #0000004d;
}

.menu-second ul li {
  width: 75%;
  height: auto;
  margin: 0 auto;
  padding: 2.6666666667vw 0;

}

.menu-second ul li figure {
  margin: 0 !important;
}

.menu-second ul li figure img {
  width: 10.6666666667vw;
}

.sidebar-wrapper ul li.active {
  background: #ffffff4d;
}
.sidebar-wrapper ul li.active span {
  color: #fff;
}
.submit-btn {
  width: 100%;
  height: 12vw;
  font-size: 4vw;
  line-height: 12vw;
  margin: 2.6666666667vw auto;
  position: relative;
  border-radius: 0.8vw;
  background: linear-gradient(to right, #82d856 0%, #5ab72a 50%);
  color: #fff;
  border: none;
  text-align: center;
}
.float-banner {
  position: fixed;
  z-index: 7999;
  bottom: 72px;
  left: 10px;
  filter: drop-shadow(0 0 1.6vw rgba(0, 0, 0, 0.5));
}
.float-banner .close {
  position: absolute;
  z-index: 2;
  top: 1vw;
  right: -4vw;
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  border-radius: 100%;
  opacity: 1;
  background: #f5f5f5;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
}
.float-banner img {
  width: auto;
  height: auto;
  min-width: 13.3333333333vw;
  min-height: 13.3333333333vw;
  max-width: 32vw;
  max-height: 32vw;
}
.games .games-main {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 2.1333333333vw;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.066667vw;
}
.games .games-box {
  position: relative;
  width: 25vw;
  height: auto;
  margin: 0 1.3333333333vw 2.6666666667vw;
  overflow: hidden;
  border-radius: 0.8vw;
}
.games .pic {
  position: relative;
  width: 100%;
  height: auto;
  line-height: 0;
}
.games .pic img {
  width: 100%;
  height: 20vw;
}
.games .games-box .text {
  display: inline-flex;
  position: relative;
  width: 100%;
  height: auto;
  padding: 6px 0px 0px 8px;
  background: #198754;
}
.games .games-box .text h3 {
  width: 83%;
  overflow: hidden;
  color: #fff;
  font-size: 3vw;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.games h5 {
  margin: 15px;
}
.games h5:before {
  content: "";
  display: inline-block;
  width: 1.0666666667vw;
  height: 4vw;
  margin-right: 1.3333333333vw;
  background-color: #198754;
}
.all-casino-page {
  padding-bottom: 30px;
  padding-top: 53px;
  background-color: #fff;
}

.search-tab ul {
  padding-top: 2.4vw !important;
  line-height: 0;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 7px 0px 10px 0.666667vw !important;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.hot-listing li {
  /* background-color: #005dac!important;
  color: #fff; */
  background: #f5f5f5 !important;
  color: #555 !important;
  margin-right: 2.6666666667vw !important;
  padding: 1px 2.666667vw !important;
  border-radius: 0.8vw !important;
  font-size: 3.4666666667vw !important;
  line-height: 8vw !important;
  vertical-align: middle !important;
  width: auto !important;
}
.hot-listing li.active {
  background-color: #61d81f !important;
  color: #fff !important;
}
.search-tab {
  /* background: #ffffff; */
  /* margin-bottom: 4.2666666667vw; */
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 13.3333333333vw;
  /* border-top: 0.2666666667vw solid #eeeeee; */
  /* border-bottom: 0.2666666667vw solid #eeeeee; */
}

.icon-holder-big .sports-icon {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  width: 45px;
  height: 36px;
  background-position: 0 -900px;
  background-size: 43px auto;
}

/* sport page css */
.ftype {
  display: block;
  margin-top: 5px;
}

.list-s {
  width: 100%;
  display: flex;
  overflow: auto;
  border-bottom: #404040 thin solid;
}

.list-box {
  float: left;
  height: 100%;
  min-height: 1px;
  padding: 5px 0;
  box-sizing: border-box;
  border-right: #404040 thin solid;
}

.list-box:last-child {
  border-right: none;
}

.list-box a {
  font-size: 13px;
  color: #fff;
  display: block;
  padding-top: 5px;
  position: relative;
  min-width: 80px;
  text-align: center;
  text-decoration: none;
}

.list-box a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: #61d81e;
  transition: 0.5s;
}

.list-box:hover a::before {
  width: 100%;
}

.list-box.active a::before {
  width: 100%;
}

.tag-live {
  position: absolute;
  top: 0;
  right: 10px;
  min-width: 33px;
  height: 12px;
  border-radius: 3px;
  padding-right: 3px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  background-image: linear-gradient(180deg, #fb3434 0, #e80505 100%);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 1;
}

.tag-live strong {
  flex: 1;
  height: 100%;
  margin-right: 3px;
  padding: 0 2px;
  border-radius: 3px 0 0 3px;
  background-image: linear-gradient(180deg, #fff 0, #eee 89%);
  display: flex;
  color: var(--black-text);
  font-weight: 600;
  color: #000;
}

.icon-holder-big {
  width: 45px;
  height: 37px;
  margin: 2px auto auto;
  align-items: center;
  display: flex;
}

.icon-holder-big .sports-icon {
  transition: all 0.5s;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url("assets/images/sports-icons.png");
  width: 45px;
  height: 36px;
  background-position: 0 -900px;
  background-size: 43px auto;
}

.icon-holder-big .live-icon {
  background-image: url("assets/images/live-icon.png");
  transition: all 0.5s;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  width: 45px;
  height: 36px;
  background-size: 36px auto;
}
.list-box-name {
  padding-bottom: 5px;
  padding: 5px 12px 0 12px;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.2s;
}

.cricket {
  background-position: 0 -502px !important;
}

.soccer {
  background-position: 0 -1578px !important;
}

.tennis {
  background-position: 0 -1684px !important;
}
.ice-hockey {
  background-position: 0 -1076px !important;
}
.casino {
  background-position: 0 -1326px !important;
}

.icon-holder-big .sports-icon.motor-sport {
  background-position: 0 -1148px !important;
}

.list-box.active .sports-icon {
  transform: scale(1.2);
  transition: all 0.5s;
}

.data-t {
  display: block;
  width: 100%;
}

.market-data {
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  /* margin: 0 10px; */
  padding: 10px;
  /* border-radius: 8px; */
  position: relative;
}

.market-data:hover {
  cursor: pointer;
  transition: all 0.2s;
}

.market-data:nth-child(odd) {
  background: rgba(196, 196, 196, 0.1);
}

.market-data .in-play {
  position: relative;
  background-image: linear-gradient(
    to right,
    #e163dc -30%,
    #c43d5d 20%,
    #5142c3 130%
  );
  color: #a13f7c;
  font-size: 8px;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  text-transform: initial;
}

.market-data .date-time {
  position: relative;
  width: 68px;
  height: 42px;
  border-radius: 5px;
  color: #b9b9b9;
  text-align: center;
  font-weight: 600;
  background-color: #4c4c4c;
  padding: 6px 1px;
  margin-right: 10px;
  display: inline-block;
  text-transform: uppercase;
  vertical-align: top;
}

.market-data .date-time ul {
  margin-top: 6px;
  margin-bottom: 0;
}

.market-data .date-time.in-play {
  position: relative;
  width: 68px;
  height: 42px;
  border-radius: 5px;
  color: #a13f7c;
  text-align: center;
  font-weight: 600;
  background-color: #d1d1d1;
  padding: 6px 1px;
  margin-right: 10px;
  text-transform: uppercase;
}

.market-data .in-play::before {
  animation-delay: -4s;
}

.market-data .in-play::after,
.market-data .in-play::before {
  content: "";
  z-index: 0;
  margin: -5%;
  box-shadow: inset 0 0 0 2px;
  border-radius: 7px;
  animation: clipMe 8s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.animate {
  overflow: hidden;
  position: relative;
  float: right;
  width: 73px;
  height: 40px;
  left: 2px;
  top: -4px;
}

.animate .flip-animation {
  animation: flip-1 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}

ul:not(.browser-default) {
  padding-left: 0;
  list-style-type: none;
}

.animate .flip-animation li {
  color: #fff;
  font-size: 9px;
  font-weight: 700;
  padding: 0;
  height: 39px;
  margin-bottom: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.animate .flip-animation li.time_date {
  display: block;
  top: 5px;
  position: relative;
}

.animate .flip-animation li.time_date {
  display: block;
  top: 5px;
  position: relative;
}

.market-data .date-time .time {
  font-size: 9px;
  white-space: nowrap;
  text-align: center;
  display: block;
  font-weight: 700;
}

.animate .flip-animation li .date,
.animate .flip-animation li .time {
  color: #fff !important;
}

.market-data .date-time .date {
  font-size: 9px;
  white-space: nowrap;
  text-align: center;
  display: block;
  font-weight: 700;
}

.market-data .in-play .in-play-light {
  display: flex;
  align-items: center;
  left: -4px;
  position: relative;
}

.icon-holder-small {
  width: 24px;
  height: 24px;
  margin: 2px auto auto;
  align-items: center;
  display: flex;
}

.market-data .in-play .in-play-light .icon-holder-small {
  width: 21px;
  top: 2px;
  position: relative;
}

.icon-holder-small .sports-icon {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url("assets/images/sports-icons.png");
  width: 24px;
  height: 20px;
  background-position: 0 -900px;
  background-size: 24px auto;
}

.icon-holder-small .sports-icon.inplay-light-icon {
  background-position: 0 -1360px;
}

@keyframes clipMe {
  0%,
  100% {
    clip: rect(0, 75px, 2px, 0);
  }

  25% {
    clip: rect(0, 2px, 75px, 0);
  }

  50% {
    clip: rect(45px, 75px, 75px, 0);
  }

  75% {
    clip: rect(0, 75px, 75px, 45px);
  }
}

@keyframes flip-1 {
  0% {
    margin-top: -90px;
  }

  30% {
    margin-top: -60px;
  }

  55% {
    margin-top: -60px;
  }

  75% {
    margin-top: 0;
  }

  99.99% {
    margin-top: 0;
  }

  100% {
    margin-top: -90px;
  }
}

.market-data a {
  color: #f3e7e7;
  display: inline-block;
  text-decoration: none;
  vertical-align: text-bottom;
}

.market-data a .game-name {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  position: relative;
  text-transform: uppercase;
}

.market-data a .game-name .versis-img {
  position: relative;
  top: 0;
  line-height: 1.5;
  display: block;
}

.market-data a .game-name .versis-img .vs-img {
  position: relative;
  margin: 0 2px;
}

.market-data a .game-name .versis-img .vs-img svg path {
  fill: #b8b8b8;
}

.market-data .game-list-time {
  width: auto;
  padding-bottom: 5px;
  line-height: 1.7;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
}

.market-data .game-list-time .game-subtitle {
  font-weight: 500;
  text-transform: capitalize;
}

.market-data .market-count {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  line-height: 1.5;
  top: 0;
  justify-content: end;
  position: relative;
}

.badge-right {
  display: flex;
}

.market-data .market-count .badge-right a {
  position: relative;
  color: #b8b8b8;
  font-weight: 700;
  cursor: pointer;
  width: 22px;
  height: 15px;
  display: inline-block;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
}

.market-data .market-count .badge-right a .badge {
  background-color: #ffb61a;
  color: #5c6060;
  padding: 3px;
  font-size: 9px;
  font-weight: 700;
  line-height: 1.2;
  border-radius: 20px;
  float: left;
  display: block;
  min-width: 19px;
  height: 16px;
  position: relative;
  top: -40px;
  left: 5px;
  right: -1px;
  box-shadow: rgba(0, 0, 0, 0.16) 2px 1px 5px, rgba(0, 0, 0, 0.23) 0 2px 5px;
}

.market-data .market-count .badge-right a .badge {
  right: 0;
  top: 3px;
  float: right;
  left: 6px;
  height: 14px;
  line-height: 0.9;
  min-width: 14px;
  border-radius: 10px;
}

.market-data .market-count .badge-right a .badge::after {
  top: 22%;
  left: -7px;
  transform: rotate(83deg);
  bottom: auto;
  border-top: 10px solid #ffb61a;
  border-left: 3px solid transparent;
  border-right: 4px solid transparent;
  display: block;
  content: "";
  position: absolute;
}

.market-data .market-count .badge-right a {
  width: auto;
  min-width: 30px;
  margin-right: 9px;
}

.ftype ul {
  margin: 0;
  padding: 0 0 0 10px;
  display: inline-flex;
  font-size: 3.7333333333333334vw;
  font-weight: 600;
}

.ftype ul li {
  position: relative;
  padding-left: 2.933333333333333vw;
  padding-right: 2.933333333333333vw;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #ededed;
  height: 26px;
  background: #ffffff1f;
}

.ftype ul li:first-child {
  border-top-left-radius: 1.3333333333333333vw;
}

.ftype ul li:last-child {
  border-top-right-radius: 1.3333333333333333vw;
}

.left-view {
  left: -8px;
  width: 8px;
  height: 8px;
  bottom: 0px;
  position: absolute;
}

.right-view {
  width: 8px;
  height: 8px;
  bottom: 0px;
  position: absolute;
  right: -8px;
}

.left-view svg,
.right-view svg {
  vertical-align: top;
}

.left-view svg g {
  fill: #2e2e2e;
}

.right-view svg g {
  fill: #2e2e2e;
}

.ftype ul li svg {
  opacity: 0;
}

.ftype ul li:first-child .left-view svg,
.ftype ul li:last-child .right-view svg {
  opacity: 1;
}

.ftype ul li.active {
  background: #ffb80c;
  color: #222;
  border-top-left-radius: 1.3333333333333333vw;
  border-top-right-radius: 1.3333333333333333vw;
}

.ftype ul li.active .left-view svg g,
.ftype ul li.active .right-view svg g {
  fill: #ffb80c;
}

.ftype ul li.active .left-view svg,
.ftype ul li.active .right-view svg {
  opacity: 1;
}
/* "sport page css" */
.language-select-div img {
  margin: auto !important;
  width: 4.8vw;
  height: 4.8vw;
  border-radius: 100%;
  box-shadow: 0 0 0.4vw #0006;
}
.language-select-div {
  min-width: 40px !important;
  width: auto !important;
  border: none !important;
  display: flex !important;
  justify-content: center;
  color: #fff;
  line-height: 21px;
  background: #012526;
}

.language-select-div {
  width: 70%;
  padding: 1.3333333333vw;
  border: 0.2666666667vw solid #eeeeee;
  border-radius: 1.3333333333vw;
  vertical-align: middle;
  text-align: center;
}
.language-select-div img {
  margin: auto !important;
}

.pop-language-select-div img {
  margin: auto !important;
  width: 4.8vw;
  height: 4.8vw;
  border-radius: 100%;
  box-shadow: 0 0 0.4vw #0006;
}

.pop-language-select-div {
  min-width: 70px !important;
  width: auto !important;
  border: none !important;
  display: flex !important;
  justify-content: center;
  color: #fff;
  height: 36px;
  line-height: 21px;
  margin-top: 10px;
  background: #3c3a30;
}
.pop-language-select-div.active {
  background: #ffb80c;
  color: #000;
}

.pop-language-select-div {
  width: 70%;
  padding: 1.3333333333vw;
  border: 0.2666666667vw solid #eeeeee;
  border-radius: 1.3333333333vw;
  /* opacity: .3; */
  vertical-align: middle;
  text-align: center;
}
.pop-language-select-div img {
  margin: auto !important;
}
.sidebar-wrapper button.bg-transparent svg path {
  fill: #fff;
}
.language-select {
  display: flex;
  justify-content: space-between;
}

.language-select-div p {
  width: 100%;
  margin-bottom: 0;
}

.settingtab span {
  background-color: rgb(54 58 61 / 1);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
}
.settingtab span svg {
  fill: #fff;
}
.settingtab.d-flex {
  gap: 5px;
  margin-right: 5px;
}
.main-list h6 {
  color: #fff;
}
.menu-first .afterlogin .main-wallet .icon-refresh.active,
.menu-first .afterlogin .balance .icon-refresh.active {
  animation: rotate 1s forwards;
}

.menu-header {
  display: flex;
  position: relative;
  width: 100%;
  height: 13.3333333333vw;
  background: #012526;
  box-shadow: inset 0 0.2666666667vw 0.2666666667vw
    color-mix(in srgb, #ffffff, transparent 80%);
  border-radius: 1.3333333333vw 1.3333333333vw 0 0;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 10px; */
  border: 0.2666666667vw solid #0b6c6e;
}
.beforelogin {
  width: 100%;
  height: 16vw;
  margin: 0;
  background: #ffb80c;
  box-shadow: none;
  border-radius: 0 0 1.3333333333vw 1.3333333333vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}

.beforelogin a {
  color: #000;
}
.closesidebar{
  top: 0!important;
  right: 0!important;
  width: 10.6666666667vw!important;
  height: 10.6666666667vw!important;

}
.close-b {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
  filter: invert(1);
}

.cricket-money.ng-star-inserted {
  max-width: 32vw;
  background: linear-gradient(180deg, #4d4d4d 0%, #363636 100%);
  border-radius: 1.3333333333vw 0 0 1.3333333333vw;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  padding: 1px 1.0666666667vw;
  -webkit-user-select: none;
  user-select: none;
  font-size: 3.2vw;
}

.exp.ng-star-inserted {
  max-width: 26.6666666667vw;
  background: #e62626;
  border-radius: 0 1.3333333333vw 1.3333333333vw 0;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  padding: 1px 1.0666666667vw;
  -webkit-user-select: none;
  user-select: none;
  font-size: 3.2vw;
}

.exp.ng-star-inserted span,
.cricket-money.ng-star-inserted span {
  margin-left: 1.0666666667vw;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: -0.1333333333vw;
  font-weight: 700;
}

.h-amt {
  align-items: center;
  display: flex;
  margin-right: 13px;
}

.casino-category .slick-slide img {
  display: block;
  filter: brightness(0) invert(1) !important;
}

.category-item .active img {
  filter: none !important;
}
.member-name p {
  font-size: 13px;
  margin-bottom: 0;
}

.afterlogin.marquee {
  width: 100%;
  height: 16vw;
  margin: 0;
  border-radius: 0 0 1.3333333333vw 1.3333333333vw;
  background: #064b4d;
  box-shadow: none;
  color: #fff;
  margin-bottom: 10px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-around;
}

.afterlogin.marquee .wallet {
  display: flex;
}

.afterlogin.marquee .wallet svg {
  margin-left: 5px;
  height: 14px;
}

.afterlogin.marquee .wallet span.amount {
  font-size: 5vw;
  font-weight: 500;
}

.wallet-box li:first-child {
  width: 39.5333333333vw;
  align-items: flex-start;
}

.wallet-box li:last-child {
  margin: 0 0 2.6666666667vw;
}
.btn-wallet .icon-wallet img {
  width: 6vw;
  height: 6vw;
  border-radius: 8vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.menu-header .icon-arrow {
  position: absolute;
  right: 4.2666666667vw;
  bottom: 2.1333333333vw;
  width: 3.3333333333vw;
  height: 3.3333333333vw;
  transform: rotate(180deg);
  background-color: var(--sidenav-profile-txt);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 60%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: 60%;
}
.deposit-tab {
  display: flex;
  align-items: center;
  border-bottom: none;
  padding: 10px 10px !important;
  background: #2b2922;
  width: 100%;
}
.deposit-tab .row {
  margin: 0;
}

.deposit-tab .mobile-header-item {
  padding: 10px;
  border-bottom: none;
  margin-bottom: 0;
  border-radius: 10px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deposit-tab .mobile-header-item.selected {
  padding: 10px;
  border-bottom: none;
  margin-bottom: 0px;
  background: #61d81f;
}
.deposit-tab .mobile-header-item.selected a {
  color: #fff;
}
.deposit-tab .mobile-header-item a {
  color: #fff;
  font-size: 15px;
  text-decoration: none;
}
.s-float-button {
  position: sticky;
  z-index: 10;
  margin: auto;
}
.s-items-center {
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0px;
}
.parlay-betsslip{
  font-size: 27px;
    margin-top: 8px;
    display: block;
}
.s-items-center  .bg-betSlip-icon-light{ position: relative;}
.menu-header  .member-name {
  width: 100%;
}


.betSlipBtn {
  align-items: center;
  background-color: #ffc800;
  border-radius: 50%;
  bottom: 6rem !important;
  display: flex;
  justify-content: center;
  padding: 12px;
  top: auto;
}
.icon-wallet {
  background-color: #043b3d;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* text-align: center; */
  /* vertical-align: middle; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-betSlip-icon-light {
  position: fixed;
  bottom: 0px;
  z-index: 9999;
  background: #616568;
  width: 46px;
  height: 42px;
  text-align: center;
  border-radius: 6px 6px 0 0;
}

.bg-betSlip-icon-light img {
  height: 40px;
}

.parlyatab {
  color: #80f40b !important;
}
li.parlyatab.active {
  background-color: #58a608 !important;
  color: #fff !important;
}
.casinon {
  background: #222;
  width: 100%;
  display: block;
  overflow: hidden;
  color: #ffffff;
  font-size: 4vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 3px 6px;
  position: absolute;
  bottom: 0;
}
#matchs::placeholder {
  color: #fff;
}
#matchs:focus {
  color: #fff;
}

.home-banner-sec .slick-slide img {
  border-radius: 0 0 20px 20px;
}
/***** Slide Right *****/
.slide-right {
  animation: 0.5s slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

.slide-top {
  animation: 0.5s slide-top;
}
@keyframes slide-top {
  from {
    margin-top: -100%;
  }
  to {
    margin-top: 0%;
  }
}
.header-language-select img {
  width: 8vw;
  height: 8vw;
}

.header-language-section {
  top: 0px;
  background: transparent;
  padding: 0;
}

.c-lang {
  font-size: 4.2666666667vw;
  font-weight: 500;
  line-height: 1.2;
  background: #2b2922;
  padding: 16px;
  margin: 0;
}

.header-language-select {
  width: 50%;
  background: #2b2922;
  margin: 0;
  display: inline-flex;
  padding: 10px;
  margin: 10px;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
}

.header-language-select div:first-child {
  display: grid;
  justify-content: center;
  width: 100%;
  text-align: center;
  gap: 5px;
}

.sports-heading .sports-icon {
  transition: all 0.5s;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(assets/images/sports-icons.png);
  width: 37px;
  height: 37px;
  background-position: 0 -900px;
  background-size: 43px auto;
  transform: scale(0.7);
}

.data-t h3 {
  background: #454545;
  color: #fff;
  margin-bottom: 15px;
  /* max-width: -webkit-max-content; */
  padding: 1.5px 7px;
  /* margin: 8px 0px 8px 0px; */
  display: inline-flex;
  font-size: 14px;
  border-bottom: #ffb61a 2px solid;
  width: 100%;
  justify-content: start;
  align-items: center;
  border-left: none;
}
.sports-heading .icon-holder-big {
  width: 40px;
  height: 37px;
  align-items: center;
  display: inline-flex;
  margin: 0;
}
.h-game {
  margin-top: 0;
  display: inline-block;
}

.login-notification {
  width: 97vw;
  margin: auto;
  margin-top: 0;
  background: #222;
  border-radius: 1.3333333333vw;
  position: fixed;
  z-index: 999999;
  text-align: center;
  left: 3%;
  top: 40%;
  width: 90%;
  padding: 0px;
  right: 3%;
}

.login-notification .pop-wrap {
  width: 100%;
  margin: auto;
  margin-top: 0;
}
.login-notification .pop-inner {
  padding: 20px;
  background: #f2f2f2;
  color: #000;
}

.login-notification .btn-close {
  position: absolute;
  right: 6px;
  top: 6px;
  /* filter: invert(1); */
  width: 14px;
  min-width: auto;
}

.login-notification .pop-title {
  background: #61d81f;
  padding: 10px;
}

.login-notification h3 {
  font-size: 17px;
  margin-bottom: 0px;
  color: #000;
}

.login-notification p {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}

.login-register-button.btn-box {
  display: flex;
  gap: 20px;
  justify-content: space-around;
}

.login-notification .button.login-button {
  background: #61d81f;
  width: 100%;
  height: 9.6vw;
  margin-top: 2.1333333333vw;
  font-size: 3.7333333333vw;
  line-height: 9.6vw;
  border-radius: 1.3333333333vw;
  font-weight: 500;
}

.login-notification .button.login-button a {
  color: #000;
}

.login-notification .button.register-button {
  background: linear-gradient(180deg, #ffb80c 0%, #ffb80c 100%);
  width: 100%;
  height: 9.6vw;
  margin-top: 2.1333333333vw;
  font-size: 3.7333333333vw;
  line-height: 9.6vw;
  border-radius: 1.3333333333vw;
  font-weight: 500;
}
.login-notification .button.register-button a {
  color: #000;
}

.active-sportBook {
  background-color: #379de6 !important;
}

.tab-btn.tab-btn-page {
  background: #61d81f;
}
.tab-btn.tab-btn-page .btn {
  width: 50%;
  margin: 0;
  border-radius: 0;
  border: none;
  background: #a5e781;
}
.tab-btn.tab-btn-page .btn.active {
  border: none;
  background: #61d81f;
}
@media only screen and (min-width: 1024px) {
  .footer-new {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .main-banner {
    display: none;
  }
  .d-div {
    width: 480px;
    margin: 90px auto 0;
  }
  .dropdown-menu.show {
    display: block;
    min-width: 220px;
    background: #0f0f0f !important;
  }
  .dropdown-menu a {
    text-align: left !important;
    display: flex !important;
    align-items: center;
  }
  .dropdown-menu a img {
    width: 30px;
    margin-right: 5px;
  }

  .ftype ul li {
    padding-left: 18px !important;
    padding-right: 18px !important;
    font-size: 17px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
  }
  .sidebar-wrapper {
    max-width: 325px;
  }
  .menu-header .icon-arrow {
    right: 1vw;
    bottom: 1.133333vw;
  }
  .footer-payment-box span {
    font-size: 18px !important;
  }
  .ftype ul li.active {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
  .ftype ul li:first-child {
    border-top-left-radius: 6px !important;
  }
  .ftype ul li:last-child {
    border-top-right-radius: 6px;
  }
  .pay {
    background: #043b3d;
    max-width: 1200px;
    margin: auto;
    padding: 2rem 0 !important;
}
.payment-inner {
  display: flex;
  margin-top: 30px;
  border-bottom: 0.2666666667vw solid #064b4d;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.pay h2 {
  color: #fff;
  font-size: 21px;
  font-weight: 600;
}
.pay ul li img {
  width: 100%;
  max-height: 60px;
}
.paymentm li img {
  height: 40px;
}
.language-sec {
  justify-content: start;
  width: 100%;
  padding: 30px 39px 15px;
  border-radius: 1.3333333333vw;
}
.language-sec h6 a {
  font-size: 21px;
  text-decoration: none;
}
  .language-select-div img {
    width: 20px;
    height: 20px;
  }
  .login-reg-wrapper {
    max-width: 1200px;
    margin: auto;
  }

  .login-header-new a {
    color: #fff;
    text-decoration: none;
    min-width: 120px;
    text-align: center;
    line-height: 40px;
    padding: 0px 10px;
    border-radius: 1.3333333333vw;
    font-size: 18px;
    display: inline-block;
    height: 40px;
  }

  .login-reg-wrapper figure {
    width: 170px;
    margin-left: 20px;
  }
  .login-reg-wrapper figcaption {
    position: absolute;
    right: 70px;
  }
  .sidebar-wrapper {
    top: 0 !important;
    padding: 0 !important;
  }
  .member-menu-box .title {
    padding: 1px 20px;
  }
  .menu-header {
    height: 60px;
    box-shadow: none;
    border-radius: 0;
  }
  .sidebar-wrapper ul a {
    font-size: 18px !important;
    margin: 0 !important;
    text-align: left;
  }

  .sidebar-wrapper ul li img {
    width: 38px !important;
    height: 38px !important;
    border-radius: 0 !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .sidebar-wrapper ul li {
    display: flex;
    flex-direction: row !important;
    justify-content: start !important;
    align-items: center;
    position: relative;
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;
    padding: 10px 20px !important;
    transition: all 0.3s;
    border-radius: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
    text-align: left !important;
    overflow: hidden;
    font-size: inherit !important;
    line-height: inherit !important;
  }
  .sidebar-wrapper ul a:hover {
    color: #61d81f !important;
  }
  .sidebar-wrapper .item-box,
  .sidebar-wrapper .contact-box,
  .sidebar-wrapper .home-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    height: auto !important;
    margin: 0;
    padding: 1.0666666667vw;
    transition: all 0.3s;
    border-radius: 1.3333333333vw;
    background: transparent !important;
    box-shadow: none !important;
    font-size: inherit !important;
    text-align: center;
    margin-bottom: 0px !important;
    overflow: hidden;
    padding: 0 0px !important;
    line-height: auto !important;
  }
  .sidebar-wrapper {
    position: fixed;
    background: #0f0f0f !important;
  }
  .close-b {
    position: absolute;
    right: auto;
    top: 10px;
    z-index: 999;
    filter: invert(1);
    left: 330px;
  }

  .overlay {
    width: 285px !important;
  }

  .beforelogin {
    display: none !important;
  }

  .login-header-new {
    left: 0; height: auto;
  }
  .home-desktop-container {
    width:580px;
    margin: 0px auto;
    padding: 20px 0;
}
  .slick-slider {
    margin-top: 80px;
  }
  .sports-team-table .back-lay-head h4 + strong {
    font-size: 21px !important;
}
.sport-accord-body .sports-team-table .back-lay-head h4 {
  font-size: 21px !important;
}
.d-flex.back-lay-head.justify-content-end.ms-auto.pb-1 {
  line-height: 23px !important;
  font-size: 18px !important;
}
.sport-accord-body .ps-4 {
  font-size: 21px !important;
}
.in-play {
  font-size: 12px !important;
}
.lay-back-table .back strong {
  font-size: 18px!important;
  line-height: 10px;
  font-weight: 500;
}
.lay-back-table .d-block {
  line-height: 8px;
  font-size: 12.64px;
}
.bg-skylight.p-3.px-sm-2{ padding-left: 0px !important; padding-right: 0 !important;} 

.bookmaker.sport-accordian{padding-left: 0px !important; padding-right: 0 !important;}

.bookmaker .accordion-header button{font-size: 21px !important;}

.all-complete button, .all-complete-all{font-size: 16px !important;}
.game-menu ul li svg {
  font-size: 1.5rem !important;
}
.game-menu ul li{height: auto !important; font-weight:600 !important; font-size:0.8rem; border-radius: 6px !important;}

.bookmaker .accordion-item {
  border-radius: 10px !important;
}
.ftype.d-flex.justify-content-between {
  margin-top: 25px;
}
.marquee-notification.d-flex {
  padding: 5px 13px 7px 5px !important;
}
.casino img{
  width: 57px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.game-menu-inner li span {
  height: 22px !important;
  width: 22px !important;
  font-size: 12px;
  line-height: 22px !important;
  text-align: center;
}



 .sports-widget .slick-slider,
  .sports-tab-panel .slick-slider {
    margin-top: 0px;
  }

  .casino-category .slick-slide img {
    width: 60px;
  }
  .games-wrapper .game-card {
    width: calc(50% - 20px);
    margin: 10px;
    padding: 0px;
  }
  .casinon {
    font-size: 24px;
  }

  .game-card .casinon {
    font-size: 16px;
  }

  .lazy-load-image-background.opacity.lazy-load-image-loaded {
    width: 100%;
  }

  .login-notification .button.login-button {
    height: 60px !important;
    margin-top: 0 !important;
    font-size: 18px !important;
    line-height: 60px !important;
  }

  .login-notification .button.register-button {
    height: 60px !important;
    margin-top: 0 !important;
    font-size: 18px !important;
    line-height: 60px !important;
  }
  .pop-wrap,
  .select-list {
    width: 100% !important;
  }

  .login-notification {
    width: 400px !important;
  }

  .login-header-new + .full-market {
    margin-top: 80px;
  }
  .match-odds-sec .match-odd-table li h2 {
    font-size: 18px !important;
  }
  .sports-listing-score .team-wise-score span {
    font-size: 18px !important;
  }

  .accordion-item .match-odd-table li h2 {
    font-size: 18px !important;
  }
  .fancy-sport-tab .match-odd-table li h2 {
    font-size: 18px !important;
  }
  .accordion-body .sports-listing-score .team-wise-score span {
    font-size: 18px !important;
  }
  .sports-tab-panel h3 {
    font-size: 17px !important;
    font-weight: 700 !important;
  }
  .game-menu {
    margin-top: 0px; height: auto !important;
  }
  .second-level-leage {
    margin-top: 80px;
  }
  .reg-data .reg-logo {
    width: 300px;
    height: auto;
    margin: 29px auto 26px;
  }
  .registration-form label,
  .login-form label {
    font-size: 18px !important;
  }
  .registration-form .form-control, .login-form .form-control {
    font-size: 18px !important;
    text-align: left;
    padding: 20px; height: 50px !important;
}
.validationcode-div {
  position: relative;
}
canvas#authenticateImage {
  height: 38px !important;
}
.games-wrapper{ padding: 0 !important;}
  .submit-btn {
    width: 100%;
    height: 60px !important;
    font-size: 20px !important;
    line-height: 60px !important;
  }
  .term-condtion {
    margin-top: 0;
  }
  .registration-form p,
  .login-form p {
    font-size: 18px;
  }
  .reg-data {
    margin-bottom: 16px;
    padding: 9px 0;
    max-width:580px;
    margin: auto;
  }

  .login-image {
    background: linear-gradient(to right, #020b0c 0%, #042d2d 50%, #022d2f 50%, #010d0d 100%);
    padding: 40px 36px;
    text-align: center;
    max-width: 580px;
    margin: auto;
}
.login-image a {
  font-size: 2vw !important;
  display: block;
  margin: 20px 0;
}

.login-image .subtext {
  color: #10a661;
  margin-bottom: 10px !important;
  font-size: 16px !important;
}

  .afterlogin.marquee {
    height: auto;
    display: none;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent !important;
  }
  .sidebar-wrapper ul.wallet-box a {
    font-size: 18px !important;
    margin: 0 !important;
    text-align: left;
    width: 100%;
    display: flex !important;
    flex-direction: row;
    align-items: center !important;
    padding: 0 !important;
  }

  .sidebar-wrapper ul.wallet-box a p {
    margin-bottom: 0 !important;
    margin-left: 20px;
  }

  .cricket-money.ng-star-inserted,
  .exp.ng-star-inserted {
    font-size: 18px;
  }

  .member-menu-box .title h2 {
    color: #fff;
    font-size: 21px;
    margin: 8px 0;
  }

  .select-group [type="radio"] + label {
    font-size: 15px;
  }
  .deposit-tab .mobile-header-item.selected a {
    color: #000 !important;
  }

  .p-title {
    max-width: 480px;
    margin: 80px auto 0 !important;
  }

  .deposit-box,
  .withdraw-box {
    max-width: 480px;
    margin: auto;
  }
  .usrTrans-form .member-menu-box {
    padding: 20px !important;
  }

  .member-menu-box .title h2:before {
    width: 5px !important;
    height: 36px !important;
  }
  .select-group .bank img {
    height: 60px !important;
  }

  .c-lang {
    font-size: 20px !important;
  }
  .header-language-select img {
    width: 60px;
    height: 60px;
  }
  .header-language-section {
    width: 330px !important;
  }
  .pop-language-select-div {
    line-height: 36px !important;
    padding: 0px !important;
  }
  .menu-second ul.active {
    left: 282px !important;
  }
  .menu-second ul {
    width: 160px;
    background: #1f1f1f !important;
    border-radius: 0px;
  }

  .menu-second ul li {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 10px;
    border-bottom: 0.2666666667vw solid #464646;
  }
  .menu-second ul li p {
    margin-bottom: 0;
  }

  .menu-second ul li figure img {
    width: 50%;
  }
  .forgetpassword-buttn {
    font-size: 16px;
  }

  .member-menu-box.balance-box .balance {
    padding: 10px 20px;
    font-size: 30px;
    height: auto;
  }
  .member-menu-content.bonuswallet {
    width: 460px !important;
    height: 100vh;
    display: none;
  }
  .menu-top {
    display: none;
  }
  .member-menu-content.bonuswallet .close-b {
    right: 0;
  }

  .member-list {
    margin: 0;
    width: 100%;
  }
  .member-menu-box ul li a p {
    font-size: 18px;
  }
  .member-menu-box ul li a .item-icon {
    width: 60px;
    height: auto;
  }
  .member-menu-box ul li a {
    height: auto;
  }
  .member-menu-logout a p {
    font-size: 20px;
  }
  .member-menu-logout {
    height: 60px;
    font-weight: 500;
    line-height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .member-menu-logout img {
    filter: invert(1);
  }

  .member-menu-logout a {
    display: flex;
  }

  .p-0.mb-0.w-100.game-menu-inner{ padding: 5px 0 !important;}

}












.casino-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  justify-content: center;
  align-items: flex-start;
  animation: fadeIn 0.5s ease;
}
.casino-iframe-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #000;
  z-index: 99;
  justify-content: center;
  align-items: flex-start;
  animation: fadeIn 0.5s ease;
}

.casino-iframe-overlay a#closepopsaba {
  position: absolute;
  width: 1em;
  height: 1em;
  line-height: 1em;
  top: 5px;
  font-size: 1.7rem;
  background-color: red !important;
  text-align: center;
  color: #fff;
  right: 8px;
  text-decoration: none;
  /* padding-bottom: 3px !important; */
}
.casino-iframe-overlay iframe {
  height: 100vh;
  width: 100%;
  border: 0px;
  position: absolute;
  z-index: 1;
  padding: 50px 0 50px 0px
}

.casinpheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0, 0, 0);
  height: 34px;
  position: relative;
  top: 0;
  z-index: 99;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.casinpheader span {
  color: #fff;
}

.react-tel-input .country-list .country,
.react-tel-input .country-list .search {
  display: flex;
}
.refer-class {
  color: #000 !important;
  min-width: 120px !important;
  font-size: 14px !important;
  background: #61d81f !important;
  padding: 5px 10px;
  border-radius: 6px;
}

.list-group-item {
  text-align: center !important;
  font-size: 21px !important;
  font-weight: 600 !important;
  display: flex !important;
  justify-content: space-between !important;
  background-color: #000 !important;
}

._failed {
  border-bottom: solid 4px red !important;
}
._failed i {
  color: red !important;
}

._success {
  box-shadow: 0 15px 25px #00000019;
  padding: 45px;
  width: 100%;
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
  background-color: #fff;
}

._success i {
  font-size: 55px;
  color: #28a745;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}
._success svg {
  height: 50px;
  fill: green;
  width: 50px;
}
._failed svg {
  fill: red;
}
.main-wallet {
  font-weight: 700;
}
.footer-new {
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}

.casino-category .slick-dots {
  position: absolute;
  bottom: 0px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.casino-category .slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 10px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.casino-category .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: auto;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.casino-category .slick-dots li button::before{
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);

}

.casino-category .slick-dots{
  padding-bottom: 4px;
}
.casino-category .slick-dots li.slick-active button:before {
  width: 1.4285714285714286rem;
  font-size: 0;
  background: #ffc800;
  height: .42857142857142855rem;
  border-radius: 0.25rem;
  margin-bottom: 0.07142857142857142rem;
  top: 1px;
  opacity: 1 !important;
}
.casino-category .slick-dots li button:before {
  line-height: initial;
  position: absolute;
  top: 0px;
  left: 0;
  width: 0.42857142857142855rem;
  height: 0.42857142857142855rem;
  content: "•";
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  display: block;
  border-radius: 50%;
  right: 0;
  margin: auto;
}
.svg-left {
  position: absolute;
  left: -19px;
  bottom: 0;
  height: 20px !important;
}
.svg-right {
  position: absolute;
  right: -19px;
  bottom: 0;
  height: 20px !important;
}
.betSlipBtn {
  align-items: center;
  background-color: #ffc800 !important;
  border-radius: 50%;
  bottom: 6rem !important;
  display: flex;
  justify-content: center;
  padding: 12px;
  top: auto;
}


.betSlipBtn span {
  background-color: red;
  border-radius: 50%;
  color: #fff;
  font-size: 8px;
  left: 60%;
  padding: 1px 4px;
  position: absolute;
  top: 10%;
}
.ftype.d-flex.justify-content-between {
  margin-top: 56px;
}
.bg-primary-odds {
  background-color: #ffb80c;
}

.reg-logo-login input {
  background-color: #064b4d !important;
  border-radius: 3px !important;
}

.reg-data input::placeholder {
  color: white !important;
}

.game-menu {
  background: linear-gradient(to bottom, #ffb80c, #fe9f00) !important;
}

.game-menu-inner {
  position: relative;
}
.game-menu-inner .animation {
  position: absolute;
  height: 100%;
  /* height: 5px; */
  top: 0;
  /* bottom: 0; */
  z-index: 0;
  background: black;
  border-radius: 1.3333333333333333vw;
  /* border-radius: 8px; */
  transition: all 0.3s ease 0s;
}
.game-menu-inner li {
  text-decoration: none;
  position: relative;
  z-index: 1;
  display: inline-block;
  text-align: center;
}
.game-menu-inner li span {
  position: absolute;
  background: black;
  color: white;
  right: 20%;
  top: 0;
  /* padding: 1px 3px; */
  border-radius: 50%;
  /* height: 3.7333333333333334vw; */
  width: 3.7333333333333334vw;
}
.game-menu-inner .active span {
  background: red !important;
}
.game-menu-inner .start-home,
li:nth-child(1):hover ~ .animation {
  width: 76px;
  left: 0;
}

.game-menu-inner li:nth-child(2):hover ~ .animation {
  width: 76px;
  left: 76px;
}

.game-menu-inner li:nth-child(3):hover ~ .animation {
  width: 76px;
  left: 152px;
}

.game-menu-inner li:nth-child(4):hover ~ .animation {
  width: 76px;
  left: 228px;
}

.game-menu-inner li:nth-child(5):hover ~ .animation {
  width: 76px;
  left: 470px;
}
.slide-up {
  /* Start the div below its normal position */
  transform: translateY(100%);
  /* Apply the animation */
  animation: slideUp 0.5s ease-in-out forwards;
}

/* Define the keyframes for the animation */
@keyframes slideUp {
  0% {
      transform: translateY(100%);
  }
  100% {
      transform: translateY(0);
  }
}

.login-image{
  background: linear-gradient(to right, #020b0c 0%, #042d2d 50%, #022d2f 50%, #010d0d 100%); 
  padding: 40px 36px; text-align: center;
  }

.login-image p{line-height: 25px;}

.login-image span{color: #f7d607;}
.login-image a{color: #ccff00 !important;
  text-decoration: none;
  font-size: 4.2vw;}

  .login-image img{    width: 80% !important;
    margin: 20px auto;}

.login-image .subtext{    color: #10a661;     margin-bottom: 3px;
  font-size: 1vh;}

  .login-image .wabtn {
    background: linear-gradient(to right, #047d5f 0%, #00800b 50%, #007f0b 50%, #047d5f 100%);
    padding: 5px 10px;
    color: #fff !important;
    font-size: 35px;
    font-weight: 700;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: auto;
    box-shadow: 0px 5px 0 #ffdf00;
}
.betbtn1 {
  bottom: 50px;
  right: 70px;
  z-index: 99;
}
.betbtn1 img {
  background-size: contain!important;
  height: 72.54px;
  width: 72.54px;
}
.slide-up {
  /* Start the div below its normal position */
  transform: translateY(100%);
  /* Apply the animation */
  animation: slideUp 0.5s ease-in-out forwards;
}

/* Define the keyframes for the animation */
@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

/* Apply this to the div you want to animate */
.slide-left {
  /* Start the div off the screen to the right */
  transform: translateX(100%);
  /* Apply the animation */
  animation: slideLeft 2s ease-in-out forwards;
}

/* Define the keyframes for the animation */
@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.lobby {
  width: 100%;
}
.lobby {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-repeat: no-repeat;
  background-size: cover;
  background: #320000b3;
  bottom: 0;
  box-shadow: inset 0 -11px 50px #85030380;
  height: 100%;
  height: 220px;
  position: fixed;
  top: auto;
  /* width: 480px; */
  z-index: 10000000000000000;
}
.gmnheader {
  align-items: end;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  margin-top: -25px;
  position: relative;
  width: 100%;
}
.luckspan {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  position: absolute;
  text-align: center;
  top: 4px;
  width: 109px;
}
.header__close img {
  height: 8.5vw;
}
.header-casino-footer {
  background: linear-gradient(90deg,#eb4e4e,#eb4e4e 74.09%,#d41b1b);
  height: 2px;
  margin-top: -2px;
}
.shadow {
  background: linear-gradient(90deg,#2c050500 19.12%,#2803037a 67.05%,#240201 118.82%);
  height: 100%;
  pointer-events: none;
  position: fixed;
  right: 0;
  width: 110px;
  z-index: 10;
}
ul.game-grid {
  align-items: center;
  display: block;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  margin-left: auto;
  margin-top: 13px;
  max-width: 340px;
  min-height: 100%;
  padding-left: 250px;
  padding-left: 10px!important;
  padding-top: 0!important;
  width: 100%;
}
.bottom-casino-slider .slick-slide {
  width: 87px!important;
}
.game-card-casino {
  border-radius: 5px;
  padding: 3px;
  position: relative;
}
.game-image {
  border-radius: 5px;
  box-shadow: 0 2px 10px #0006;
  display: block;
  height: 90%;
  object-fit: contain;
  width: 90%!important;
}
.casino-play-inner {
  height: 100%;
}

.game-grid-new {
  min-height: 100%;
  height: 240px !important;
  padding-top: 0 !important;
}
.betbtn2 {
  align-items: center;
  background: #043b3d;
  border-top-left-radius: 5px;
  border-radius: 50%;
  bottom: 57px;
  cursor: vertical-text;
  display: grid;
  height: 3.4285714285714284rem;
  right: 10px;
  text-align: center;
  text-transform: 1px 1px 0 #00000080;
  text-transform: capitalize;
  width: 3.4285714285714284rem;
  z-index: 10;
  z-index: 9999;
}
.text-24 {
  font-size: 1.7142857142857142rem;
}
